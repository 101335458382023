import React from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  useTheme,
  alpha,
  Divider,
  Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';

const ProjectDemo: React.FC = () => {
  const theme = useTheme();

  const sections = [
    {
      title: 'Project Overview',
      content: `The Fly-RSNN project implements a biologically inspired recurrent spiking neural network 
      that mimics the learning mechanisms found in the fruit fly's neural circuit. This research 
      combines computational neuroscience with machine learning to create more efficient and 
      biologically plausible neural networks.`
    },
    {
      title: 'Neural Architecture',
      content: `The network architecture is inspired by the fruit fly's mushroom body, consisting of:
      • Input layer simulating olfactory projection neurons
      • Hidden layer representing Kenyon cells
      • Output layer modeling mushroom body output neurons
      • Dopaminergic neurons for reinforcement learning`
    },
    {
      title: 'Implementation Details',
      content: `Key technical aspects of the implementation include:
      • Custom PyTorch implementation of spiking neurons
      • Biologically plausible learning rules
      • Spike-timing-dependent plasticity (STDP)
      • Efficient sparse connectivity
      • Real-time visualization of neural activity`
    },
    {
      title: 'Results & Findings',
      content: `Our experiments demonstrated:
      • Successful learning of temporal patterns
      • Robust memory formation and recall
      • Energy efficiency compared to traditional ANNs
      • Biological plausibility of learning mechanisms
      • Scalability to larger networks`
    }
  ];

  return (
    <Box
      sx={{
        minHeight: '100vh',
        pt: 8,
        pb: 6,
        background: `linear-gradient(to bottom, ${alpha(theme.palette.background.default, 0.8)}, ${alpha(theme.palette.background.default, 0.9)})`,
      }}
    >
      <Container maxWidth="lg">
        <Button
          component={RouterLink}
          to="/projects"
          startIcon={<ArrowBackIcon />}
          sx={{ mb: 4 }}
        >
          Back to Projects
        </Button>

        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            color: theme.palette.text.primary,
            mb: 4
          }}
        >
          Fly-RSNN Demo & Details
        </Typography>

        <Grid container spacing={4}>
          {sections.map((section, index) => (
            <Grid item xs={12} key={index}>
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  background: alpha(theme.palette.background.paper, 0.9),
                  borderRadius: 2,
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: `0 8px 24px ${alpha(theme.palette.common.black, 0.15)}`,
                  }
                }}
              >
                <Typography
                  variant="h4"
                  component="h2"
                  gutterBottom
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    mb: 3
                  }}
                >
                  {section.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.text.secondary,
                    whiteSpace: 'pre-line',
                    lineHeight: 1.8,
                    fontSize: '1.1rem'
                  }}
                >
                  {section.content}
                </Typography>
              </Paper>
            </Grid>
          ))}

          {/* 这里可以添加更多内容，如：
              - 交互式演示
              - 代码示例
              - 可视化图表
              - 实验结果
              根据需要继续扩展 */}
        </Grid>
      </Container>
    </Box>
  );
};

export default ProjectDemo; 