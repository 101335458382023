import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Typography,
  Button,
  Container,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  useTheme,
  useMediaQuery,
  alpha,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import CodeIcon from '@mui/icons-material/Code';
import ArticleIcon from '@mui/icons-material/Article';

interface LayoutProps {
  children: React.ReactNode;
}

interface NavItem {
  text: string;
  path: string;
  icon: React.ReactElement;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  const isDemoPage = location.pathname.includes('/demo');

  const navItems: NavItem[] = [
    { text: 'HOME', path: '/', icon: <HomeIcon /> },
    { text: 'ABOUT', path: '/about', icon: <PersonIcon /> },
    { text: 'PROJECTS', path: '/projects', icon: <CodeIcon /> },
    { text: 'BLOG', path: '/blog', icon: <ArticleIcon /> },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <List sx={{ 
      background: `linear-gradient(to bottom, ${alpha(theme.palette.background.paper, 0.95)}, ${alpha(theme.palette.background.paper, 0.98)})`,
      height: '100%',
      backdropFilter: 'blur(8px)',
    }}>
      {navItems.map((item) => {
        const isActive = location.pathname === item.path;
        return (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              component={RouterLink}
              to={item.path}
              onClick={handleDrawerToggle}
              sx={{
                borderLeft: '4px solid',
                borderColor: isActive ? 'primary.main' : 'transparent',
                backgroundColor: isActive ? alpha(theme.palette.primary.main, 0.08) : 'transparent',
                transition: 'all 0.3s ease',
                '&:hover': {
                  borderColor: isActive ? 'primary.main' : alpha(theme.palette.primary.main, 0.5),
                  backgroundColor: alpha(theme.palette.primary.main, 0.08),
                }
              }}
            >
              <ListItemIcon sx={{ 
                color: isActive ? 'primary.main' : 'text.secondary',
                minWidth: 40,
              }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText 
                primary={item.text} 
                sx={{
                  color: isActive ? 'primary.main' : 'text.primary',
                  '& .MuiListItemText-primary': {
                    fontWeight: isActive ? 600 : 400,
                  }
                }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {!isDemoPage && (
        <>
          {isMobile && (
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ 
                position: 'fixed', 
                top: 16, 
                left: 16, 
                zIndex: 1200,
                background: `linear-gradient(135deg, ${alpha(theme.palette.background.paper, 0.9)}, ${alpha(theme.palette.background.paper, 0.95)})`,
                backdropFilter: 'blur(8px)',
                boxShadow: `0 4px 12px ${alpha(theme.palette.common.black, 0.08)}`,
                borderRadius: '12px',
                width: 40,
                height: 40,
                '&:hover': {
                  background: `linear-gradient(135deg, ${alpha(theme.palette.background.paper, 0.95)}, ${alpha(theme.palette.background.paper, 1)})`,
                }
              }}
            >
              <MenuIcon sx={{ color: theme.palette.text.primary }} />
            </IconButton>
          )}

          {!isMobile && (
            <Box 
              sx={{ 
                position: 'fixed',
                top: 16,
                right: 16,
                zIndex: 1200,
                display: 'flex',
                gap: 1,
                padding: '8px',
                borderRadius: '16px',
                background: `linear-gradient(135deg, ${alpha(theme.palette.background.paper, 0.8)}, ${alpha(theme.palette.background.paper, 0.9)})`,
                backdropFilter: 'blur(8px)',
                boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.1)}`,
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: `linear-gradient(135deg, ${alpha(theme.palette.background.paper, 0.85)}, ${alpha(theme.palette.background.paper, 0.95)})`,
                  transform: 'translateY(-2px)',
                  boxShadow: `0 8px 24px ${alpha(theme.palette.common.black, 0.12)}`,
                }
              }}
            >
              {navItems.map((item) => {
                const isActive = location.pathname === item.path;
                return (
                  <Button
                    key={item.text}
                    component={RouterLink}
                    to={item.path}
                    startIcon={item.icon}
                    sx={{
                      color: isActive ? 'primary.main' : 'text.primary',
                      fontWeight: isActive ? 600 : 400,
                      px: 2,
                      py: 1,
                      borderRadius: '12px',
                      position: 'relative',
                      overflow: 'hidden',
                      transition: 'all 0.3s ease',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: isActive ? '100%' : '0%',
                        height: '2px',
                        backgroundColor: 'primary.main',
                        transition: 'all 0.3s ease',
                      },
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.08),
                        '&::before': {
                          width: '100%',
                        }
                      }
                    }}
                  >
                    {item.text}
                  </Button>
                );
              })}
            </Box>
          )}

          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: {
                width: 280,
                background: 'transparent',
                boxShadow: 'none',
              }
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { 
                boxSizing: 'border-box',
                borderRight: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
              },
            }}
          >
            {drawer}
          </Drawer>
        </>
      )}

      <Box component="main" sx={{ flex: 1 }}>
        {children}
      </Box>

      {!isDemoPage && (
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            background: `linear-gradient(to bottom, ${alpha(theme.palette.background.paper, 0.8)}, ${alpha(theme.palette.background.paper, 0.9)})`,
            backdropFilter: 'blur(8px)',
          }}
        >
          <Container maxWidth="sm">
            <Typography variant="body2" color="text.secondary" align="center">
              © {new Date().getFullYear()} My Portfolio. All rights reserved.
            </Typography>
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default Layout; 