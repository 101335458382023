import React, { useEffect, useState } from 'react';
import { 
  Typography, 
  Box, 
  Button, 
  Container, 
  useTheme, 
  alpha,
  Card,
  CardContent,
  CardActions,
  Grid,
  Chip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GitHubIcon from '@mui/icons-material/GitHub';
import ArticleIcon from '@mui/icons-material/Article';
import CodeIcon from '@mui/icons-material/Code';
import profileImage from '../images/MyPhoto.jpg';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 30);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const currentProject = {
    title: 'Fly-RSNN: Bio-inspired Neural Network',
    description: 'A biologically inspired recurrent spiking neural network model that simulates the fruit fly\'s neural circuit for robust learning and memory.',
    technologies: ['Python', 'PyTorch', 'Computational Neuroscience'],
    githubUrl: 'https://github.com/xin-san/fly-rsnn',
    demoPath: '/projects/fly-rsnn-demo'
  };

  const latestBlogPosts = [
    {
      title: 'Implementing Bio-inspired Neural Networks',
      date: '2024-03-10',
      summary: 'Exploring the implementation details of biologically inspired neural networks and their applications in modern AI systems.',
      tags: ['Neural Networks', 'AI', 'Research'],
    }
  ];

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        background: `linear-gradient(135deg, ${alpha('#1a1a1a', 0.97)}, ${alpha('#2c3e50', 0.97)})`,
        '&::before': {
          content: '""',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at 20% 30%, rgba(52, 152, 219, 0.15) 0%, rgba(52, 152, 219, 0) 50%)',
          zIndex: 1,
        },
        '&::after': {
          content: '""',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at 80% 70%, rgba(231, 76, 60, 0.1) 0%, rgba(231, 76, 60, 0) 50%)',
          zIndex: 1,
        }
      }}
    >
      {/* Hero Section */}
      <Container 
        maxWidth="lg" 
        sx={{ 
          position: 'relative',
          zIndex: 2,
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* Profile Section */}
        <Box 
          sx={{ 
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            gap: { xs: 4, md: 8 },
            width: '100%',
          }}
        >
          {/* Left Content */}
          <Box 
            sx={{ 
              flex: 1,
              textAlign: { xs: 'center', md: 'left' },
              animation: 'fadeInLeft 1s ease-out',
              '@keyframes fadeInLeft': {
                from: {
                  opacity: 0,
                  transform: 'translateX(-20px)',
                },
                to: {
                  opacity: 1,
                  transform: 'translateX(0)',
                },
              },
            }}
          >
            <Typography 
              variant="h1" 
              sx={{
                fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' },
                fontWeight: 700,
                color: 'white',
                mb: 2,
                letterSpacing: '-0.02em',
                lineHeight: 1.2,
              }}
            >
              Hi, I'm Tan Xin
            </Typography>
            <Typography 
              variant="h2" 
              sx={{
                fontSize: { xs: '1.5rem', md: '2rem' },
                fontWeight: 500,
                color: alpha('#fff', 0.8),
                mb: 4,
                letterSpacing: '0.02em',
              }}
            >
              Neuroscience Researcher & AI Developer
            </Typography>
            <Typography 
              variant="body1" 
              sx={{
                fontSize: { xs: '1rem', md: '1.1rem' },
                color: alpha('#fff', 0.7),
                mb: 4,
                maxWidth: '600px',
                lineHeight: 1.8,
                mx: { xs: 'auto', md: 0 },
              }}
            >
              Exploring the intersection of neuroscience and artificial intelligence, 
              focusing on bio-inspired neural networks and cognitive computing systems.
            </Typography>
            <Box 
              sx={{ 
                display: 'flex', 
                gap: 2,
                justifyContent: { xs: 'center', md: 'flex-start' },
              }}
            >
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate('/about')}
                sx={{
                  backgroundColor: '#3498db',
                  px: 4,
                  py: 1.5,
                  borderRadius: '30px',
                  fontSize: '1.1rem',
                  textTransform: 'none',
                  boxShadow: '0 4px 20px rgba(52, 152, 219, 0.3)',
                  '&:hover': {
                    backgroundColor: '#2980b9',
                    transform: 'translateY(-2px)',
                    boxShadow: '0 8px 24px rgba(52, 152, 219, 0.4)',
                  },
                  transition: 'all 0.3s ease',
                }}
              >
                About Me
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={() => navigate('/projects')}
                endIcon={<ArrowForwardIcon />}
                sx={{
                  color: 'white',
                  borderColor: alpha('#fff', 0.3),
                  px: 4,
                  py: 1.5,
                  borderRadius: '30px',
                  fontSize: '1.1rem',
                  textTransform: 'none',
                  '&:hover': {
                    borderColor: 'white',
                    backgroundColor: alpha('#fff', 0.05),
                    transform: 'translateY(-2px)',
                  },
                  transition: 'all 0.3s ease',
                }}
              >
                View Projects
              </Button>
            </Box>
          </Box>

          {/* Profile Image */}
          <Box 
            sx={{ 
              position: 'relative',
              animation: 'fadeInRight 1s ease-out',
              '@keyframes fadeInRight': {
                from: {
                  opacity: 0,
                  transform: 'translateX(20px)',
                },
                to: {
                  opacity: 1,
                  transform: 'translateX(0)',
                },
              },
            }}
          >
            <Box
              component="img"
              src={profileImage}
              alt="Profile"
              sx={{
                width: { xs: '280px', md: '320px', lg: '360px' },
                height: { xs: '280px', md: '320px', lg: '360px' },
                borderRadius: '20px',
                objectFit: 'cover',
                boxShadow: '0 20px 40px rgba(0,0,0,0.3)',
                border: `4px solid ${alpha('#fff', 0.1)}`,
                filter: 'brightness(1.1)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: '0 24px 48px rgba(0,0,0,0.4)',
                },
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: -20,
                left: -20,
                right: -20,
                bottom: -20,
                border: `2px solid ${alpha('#fff', 0.1)}`,
                borderRadius: '30px',
                zIndex: -1,
                animation: 'rotate 10s linear infinite',
                '@keyframes rotate': {
                  from: {
                    transform: 'rotate(0deg)',
                  },
                  to: {
                    transform: 'rotate(360deg)',
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Container>

      {/* Recent Work Section with Scroll Animation */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          minHeight: '100vh',
          pt: 8,
          pb: 4,
          opacity: isScrolled ? 1 : 0,
          transform: isScrolled ? 'translateY(0)' : 'translateY(20px)',
          transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
          visibility: isScrolled ? 'visible' : 'hidden',
        }}
      >
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            sx={{ 
              color: 'white',
              mb: 4,
              textAlign: 'center',
              fontSize: { xs: '2rem', md: '2.5rem' },
            }}
          >
            Recent Work
          </Typography>
          <Grid container spacing={4}>
            {/* Current Project Card */}
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  height: '100%',
                  background: alpha('#fff', 0.05),
                  backdropFilter: 'blur(10px)',
                  borderRadius: 4,
                  border: `1px solid ${alpha('#fff', 0.1)}`,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: `0 16px 48px ${alpha('#000', 0.3)}`,
                  }
                }}
              >
                <CardContent sx={{ p: 4 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <CodeIcon sx={{ color: '#3498db', mr: 1 }} />
                    <Typography variant="h5" sx={{ color: 'white' }}>
                      Current Project
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ color: '#3498db', mb: 2 }}>
                    {currentProject.title}
                  </Typography>
                  <Typography sx={{ color: alpha('#fff', 0.7), mb: 3 }}>
                    {currentProject.description}
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                    {currentProject.technologies.map((tech) => (
                      <Chip
                        key={tech}
                        label={tech}
                        size="small"
                        sx={{
                          backgroundColor: alpha('#3498db', 0.1),
                          color: '#3498db',
                          borderRadius: '15px',
                        }}
                      />
                    ))}
                  </Box>
                </CardContent>
                <CardActions sx={{ p: 4, pt: 0 }}>
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<GitHubIcon />}
                    href={currentProject.githubUrl}
                    target="_blank"
                    sx={{
                      mr: 2,
                      backgroundColor: '#2c3e50',
                      '&:hover': {
                        backgroundColor: '#34495e',
                      }
                    }}
                  >
                    GitHub
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => navigate(currentProject.demoPath)}
                    sx={{
                      color: '#3498db',
                      borderColor: '#3498db',
                      '&:hover': {
                        borderColor: '#2980b9',
                        backgroundColor: alpha('#3498db', 0.1),
                      }
                    }}
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            {/* Latest Blog Post Card */}
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  height: '100%',
                  background: alpha('#fff', 0.05),
                  backdropFilter: 'blur(10px)',
                  borderRadius: 4,
                  border: `1px solid ${alpha('#fff', 0.1)}`,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: `0 16px 48px ${alpha('#000', 0.3)}`,
                  }
                }}
              >
                <CardContent sx={{ p: 4 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <ArticleIcon sx={{ color: '#e74c3c', mr: 1 }} />
                    <Typography variant="h5" sx={{ color: 'white' }}>
                      Latest Blog Post
                    </Typography>
                  </Box>
                  {latestBlogPosts.map((post, index) => (
                    <Box key={index}>
                      <Typography variant="h6" sx={{ color: '#e74c3c', mb: 1 }}>
                        {post.title}
                      </Typography>
                      <Typography variant="caption" sx={{ color: alpha('#fff', 0.5), display: 'block', mb: 2 }}>
                        {post.date}
                      </Typography>
                      <Typography sx={{ color: alpha('#fff', 0.7), mb: 3 }}>
                        {post.summary}
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {post.tags.map((tag) => (
                          <Chip
                            key={tag}
                            label={tag}
                            size="small"
                            sx={{
                              backgroundColor: alpha('#e74c3c', 0.1),
                              color: '#e74c3c',
                              borderRadius: '15px',
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  ))}
                </CardContent>
                <CardActions sx={{ p: 4, pt: 0 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => navigate('/blog')}
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                      color: '#e74c3c',
                      borderColor: '#e74c3c',
                      '&:hover': {
                        borderColor: '#c0392b',
                        backgroundColor: alpha('#e74c3c', 0.1),
                      }
                    }}
                  >
                    Read More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Scroll Indicator */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 40,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 2,
          opacity: isScrolled ? 0 : 1,
          transition: 'opacity 0.3s ease',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: alpha('#fff', 0.7),
            fontSize: '0.9rem',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
          }}
        >
          Scroll to explore
        </Typography>
        <Box
          sx={{
            width: 24,
            height: 40,
            border: `2px solid ${alpha('#fff', 0.3)}`,
            borderRadius: 12,
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 6,
              left: '50%',
              transform: 'translateX(-50%)',
              width: 4,
              height: 4,
              backgroundColor: alpha('#fff', 0.7),
              borderRadius: '50%',
              animation: 'scrollIndicator 1.5s infinite',
            },
            '@keyframes scrollIndicator': {
              '0%': {
                opacity: 1,
                transform: 'translate(-50%, 0)',
              },
              '100%': {
                opacity: 0,
                transform: 'translate(-50%, 15px)',
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Home; 