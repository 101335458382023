import React from 'react';
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  useTheme,
  alpha,
  Container,
  Chip,
} from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import InfoIcon from '@mui/icons-material/Info';
import CodeIcon from '@mui/icons-material/Code';
import { Link as RouterLink } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface Project {
  id: number;
  title: string;
  description: string;
  technologies: string[];
  features: string[];
  githubUrl?: string;
  demoPath?: string;
}

const Projects: React.FC = () => {
  const theme = useTheme();
  
  const projects: Project[] = [
    {
      id: 1,
      title: 'Fly-RSNN: Bio-inspired Spiking Neural Network',
      description: 'A biologically inspired recurrent spiking neural network model that simulates the fruit fly\'s neural circuit for robust learning and memory. This project implements a novel architecture combining the efficiency of spiking neural networks with the biological principles observed in Drosophila\'s learning mechanisms.',
      technologies: [
        'Python',
        'PyTorch',
        'NumPy',
        'Matplotlib',
        'Computational Neuroscience',
        'Spiking Neural Networks'
      ],
      features: [
        'Implementation of bio-inspired recurrent spiking neural network',
        'Simulation of fruit fly\'s neural circuit mechanisms',
        'Efficient learning and memory capabilities',
        'Visualization of neural activity and network dynamics',
        'Comprehensive documentation and experimental results'
      ],
      githubUrl: 'https://github.com/xin-san/fly-rsnn',
      demoPath: '/projects/fly-rsnn-demo'
    }
  ];

  return (
    <Box sx={{ 
      minHeight: '100vh',
      pt: 8,
      pb: 6,
      background: `linear-gradient(135deg, ${alpha('#f5f5f5', 0.9)}, ${alpha('#e0e0e0', 0.9)})`
    }}>
      <Container maxWidth="lg">
        <Box sx={{ mb: 6, textAlign: 'center' }}>
          <Typography 
            variant="h3" 
            component="h1" 
            gutterBottom
            sx={{ 
              fontWeight: 'bold',
              color: '#2c3e50',
              mb: 2
            }}
          >
            Projects
          </Typography>
          <Typography 
            variant="h6" 
            sx={{ 
              maxWidth: '800px', 
              mx: 'auto', 
              mb: 4,
              color: '#34495e'
            }}
          >
            Exploring the intersection of neuroscience and artificial intelligence
          </Typography>
        </Box>

        {projects.map((project) => (
          <Card
            key={project.id}
            elevation={3}
            sx={{
              borderRadius: 4,
              background: 'rgba(255, 255, 255, 0.95)',
              transition: 'all 0.3s ease-in-out',
              border: `1px solid ${alpha('#e0e0e0', 0.5)}`,
              '&:hover': {
                transform: 'translateY(-8px)',
                boxShadow: '0 16px 48px rgba(0, 0, 0, 0.12)',
                background: 'rgba(255, 255, 255, 0.98)',
              }
            }}
          >
            <CardContent sx={{ p: 4 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <CodeIcon sx={{ fontSize: 32, color: '#3498db', mr: 2 }} />
                <Typography variant="h4" component="h2" fontWeight="bold" sx={{ color: '#2c3e50' }}>
                  {project.title}
                </Typography>
              </Box>

              <Typography 
                variant="body1" 
                paragraph 
                sx={{ 
                  mb: 4, 
                  fontSize: '1.1rem', 
                  lineHeight: 1.6,
                  textAlign: 'justify',
                  color: '#34495e'
                }}
              >
                {project.description}
              </Typography>

              <Box sx={{ mb: 4 }}>
                <Typography variant="h6" gutterBottom sx={{ color: '#3498db', fontWeight: 'bold' }}>
                  Key Features
                </Typography>
                <Box component="ul" sx={{ pl: 2 }}>
                  {project.features.map((feature, index) => (
                    <Typography 
                      key={index} 
                      component="li" 
                      sx={{ 
                        mb: 1,
                        color: '#34495e',
                        '&::marker': {
                          color: '#3498db'
                        }
                      }}
                    >
                      {feature}
                    </Typography>
                  ))}
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom sx={{ color: '#3498db', fontWeight: 'bold' }}>
                  Technologies Used
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {project.technologies.map((tech) => (
                    <Chip
                      key={tech}
                      label={tech}
                      sx={{
                        backgroundColor: alpha('#3498db', 0.1),
                        color: '#3498db',
                        fontWeight: 500,
                        border: `1px solid ${alpha('#3498db', 0.2)}`,
                        '&:hover': {
                          backgroundColor: alpha('#3498db', 0.15),
                        }
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </CardContent>

            <CardActions sx={{ px: 4, pb: 4 }}>
              {project.githubUrl && (
                <Button
                  variant="contained"
                  startIcon={<GitHubIcon />}
                  href={project.githubUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ 
                    mr: 2,
                    backgroundColor: '#2c3e50',
                    '&:hover': {
                      backgroundColor: '#34495e',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 8px 16px rgba(44, 62, 80, 0.3)',
                    }
                  }}
                >
                  View on GitHub
                </Button>
              )}
              {project.demoPath && (
                <Button
                  variant="outlined"
                  startIcon={<VisibilityIcon />}
                  component={RouterLink}
                  to={project.demoPath}
                  sx={{ 
                    borderWidth: 2,
                    borderColor: '#e74c3c',
                    color: '#e74c3c',
                    '&:hover': {
                      borderWidth: 2,
                      borderColor: '#c0392b',
                      backgroundColor: alpha('#e74c3c', 0.05),
                      color: '#c0392b',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 8px 16px rgba(231, 76, 60, 0.2)',
                    }
                  }}
                >
                  View Demo & Details
                </Button>
              )}
            </CardActions>
          </Card>
        ))}
      </Container>
    </Box>
  );
};

export default Projects; 