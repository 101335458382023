import React from 'react';
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Chip,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';

interface BlogPost {
  id: number;
  title: string;
  summary: string;
  author: string;
  date: string;
  tags: string[];
}

const Blog: React.FC = () => {
  const posts: BlogPost[] = [
    {
      id: 1,
      title: 'React 18 New Features Analysis',
      summary: 'Deep dive into React 18\'s new features, including concurrent rendering, automatic batching, and other important updates.',
      author: 'John Doe',
      date: '2024-03-05',
      tags: ['React', 'JavaScript', 'Frontend'],
    },
    {
      id: 2,
      title: 'Python Async Programming in Practice',
      summary: 'Introduction to async programming concepts in Python and how to build high-performance applications using asyncio and FastAPI.',
      author: 'Jane Smith',
      date: '2024-03-03',
      tags: ['Python', 'AsyncIO', 'Backend'],
    },
    {
      id: 3,
      title: 'Modern Web Development Best Practices',
      summary: 'Exploring best practices in modern web development, including performance optimization, security considerations, and development workflows.',
      author: 'Mike Johnson',
      date: '2024-03-01',
      tags: ['Web Development', 'Best Practices', 'Performance'],
    },
  ];

  return (
    <Box>
      <Typography variant="h3" component="h1" gutterBottom>
        Technical Blog
      </Typography>

      <Grid container spacing={4}>
        {posts.map((post) => (
          <Grid item xs={12} key={post.id}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  {post.title}
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, mb: 2, color: 'text.secondary' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PersonIcon sx={{ fontSize: 20, mr: 0.5 }} />
                    <Typography variant="body2">{post.author}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccessTimeIcon sx={{ fontSize: 20, mr: 0.5 }} />
                    <Typography variant="body2">{post.date}</Typography>
                  </Box>
                </Box>
                <Typography paragraph color="text.secondary">
                  {post.summary}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {post.tags.map((tag) => (
                    <Chip
                      key={tag}
                      label={tag}
                      size="small"
                      variant="outlined"
                    />
                  ))}
                </Box>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary">
                  Read More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Blog; 