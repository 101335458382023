import React from 'react';
import { 
  Typography, 
  Box, 
  Paper, 
  Grid, 
  Chip,
  useTheme,
  alpha,
  Container
} from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import TimelineIcon from '@mui/icons-material/Timeline';

interface Skill {
  name: string;
  level: 'Expert' | 'Advanced' | 'Intermediate' | 'Basic';
}

const About: React.FC = () => {
  const theme = useTheme();

  const skills = [
    'JavaScript', 'TypeScript', 'React', 'Node.js',
    'Python', 'FastAPI', 'SQL', 'Git'
  ];

  const education = [
    {
      period: '2024-Present',
      school: 'The University of Tokyo',
      major: 'Neuroscience',
      degree: null,
    },
    {
      period: '2017-2021',
      school: 'China University of Petroleum-Beijing',
      major: 'Automation',
      degree: "Bachelor's Degree",
    },
  ];

  const workExperience = [
    {
      period: '2024.09-Present',
      company: 'つくるAI株式会社',
      companyEn: 'Tsukuru AI Inc.',
      position: 'Engineer',
      type: 'Internship',
      description: 'Working on AI and software development projects.',
      currentProject: {
        name: 'Document Information Extraction System',
        description: 'Training YOLO models for information annotation on product specification documents and implementing OCR technology for text extraction.'
      }
    }
  ];

  const skillCategories = [
    {
      category: 'Frontend Development',
      skills: [
        { name: 'React', level: 'Advanced' },
        { name: 'TypeScript', level: 'Advanced' },
        { name: 'JavaScript', level: 'Advanced' },
        { name: 'HTML5', level: 'Expert' },
        { name: 'CSS3', level: 'Expert' },
        { name: 'Material-UI', level: 'Advanced' }
      ] as Skill[]
    },
    {
      category: 'Backend Development',
      skills: [
        { name: 'Python', level: 'Expert' },
        { name: 'FastAPI', level: 'Advanced' },
        { name: 'Node.js', level: 'Intermediate' },
        { name: 'RESTful APIs', level: 'Advanced' }
      ] as Skill[]
    },
    {
      category: 'AI & Machine Learning',
      skills: [
        { name: 'YOLO', level: 'Advanced' },
        { name: 'OCR', level: 'Advanced' },
        { name: 'PyTorch', level: 'Intermediate' },
        { name: 'Computer Vision', level: 'Intermediate' }
      ] as Skill[]
    },
    {
      category: 'Database & Tools',
      skills: [
        { name: 'SQL', level: 'Advanced' },
        { name: 'MongoDB', level: 'Intermediate' },
        { name: 'Git', level: 'Advanced' },
        { name: 'Docker', level: 'Basic' }
      ] as Skill[]
    },
    {
      category: 'Languages',
      skills: [
        { name: 'Chinese (Native)', level: 'Expert' },
        { name: 'English (Professional)', level: 'Advanced' },
        { name: 'Japanese (Business)', level: 'Intermediate' }
      ] as Skill[]
    }
  ];

  const getSkillColor = (level: Skill['level']) => {
    switch (level) {
      case 'Expert':
        return {
          border: '#2e7d32',
          bg: '#2e7d32',
          hover: '#1b5e20',
          gradient: 'linear-gradient(135deg, #2e7d32 0%, #1b5e20 100%)'
        };
      case 'Advanced':
        return {
          border: '#1976d2',
          bg: '#1976d2',
          hover: '#1565c0',
          gradient: 'linear-gradient(135deg, #1976d2 0%, #1565c0 100%)'
        };
      case 'Intermediate':
        return {
          border: '#ed6c02',
          bg: '#ed6c02',
          hover: '#e65100',
          gradient: 'linear-gradient(135deg, #ed6c02 0%, #e65100 100%)'
        };
      case 'Basic':
        return {
          border: '#757575',
          bg: '#757575',
          hover: '#616161',
          gradient: 'linear-gradient(135deg, #757575 0%, #616161 100%)'
        };
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',
        '&::before': {
          content: '""',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: 'url(/images/background.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          opacity: 1,
          zIndex: -2,
          filter: 'contrast(1.1) brightness(1.05)',
        },
        '&::after': {
          content: '""',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `linear-gradient(to bottom, 
            ${alpha(theme.palette.background.default, 0.2)},
            ${alpha(theme.palette.background.default, 0.3)}
          )`,
          backdropFilter: 'blur(4px)',
          zIndex: -1,
        }
      }}
    >
      <Box sx={{ textAlign: 'center', mb: 6, pt: 8 }}>
        <Typography 
          variant="h2" 
          component="h1" 
          sx={{ 
            fontWeight: 'bold',
            color: theme.palette.text.primary,
            textShadow: `2px 2px 4px ${alpha(theme.palette.common.black, 0.2)}`,
            mb: 2,
            opacity: 0.9,
            transform: 'translateY(0)',
            transition: 'transform 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
            '&:hover': {
              transform: 'translateY(-5px)',
            }
          }}
        >
          About Me
        </Typography>
      </Box>

      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={3}
              sx={{ 
                p: 3, 
                mb: 3,
                background: alpha(theme.palette.background.paper, 0.9),
                backdropFilter: 'blur(4px)',
                borderRadius: 2,
                boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.1)}`,
                transform: 'translateY(0)',
                transition: 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
                '&:hover': {
                  transform: 'translateY(-8px) scale(1.02)',
                  background: alpha(theme.palette.background.paper, 0.95),
                  boxShadow: `0 16px 48px ${alpha(theme.palette.common.black, 0.2)}`,
                }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <SchoolIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="h5" component="h2" sx={{ color: 'primary.main' }}>
                  Education
                </Typography>
              </Box>
              {education.map((edu, index) => (
                <Box 
                  key={index} 
                  sx={{ 
                    mb: index !== education.length - 1 ? 2 : 0,
                    pl: 2,
                    borderLeft: '2px solid',
                    borderColor: 'primary.main',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      pl: 3,
                      borderColor: 'secondary.main'
                    }
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {edu.school}
                  </Typography>
                  <Typography color="text.secondary" gutterBottom>
                    {edu.period}
                  </Typography>
                  <Typography>
                    {edu.major}
                    {edu.degree && ` - ${edu.degree}`}
                  </Typography>
                </Box>
              ))}
            </Paper>

            <Paper 
              elevation={3}
              sx={{ 
                p: 3, 
                mb: 3,
                background: alpha(theme.palette.background.paper, 0.9),
                backdropFilter: 'blur(4px)',
                borderRadius: 2,
                boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.1)}`,
                transform: 'translateY(0)',
                transition: 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
                '&:hover': {
                  transform: 'translateY(-8px) scale(1.02)',
                  background: alpha(theme.palette.background.paper, 0.95),
                  boxShadow: `0 16px 48px ${alpha(theme.palette.common.black, 0.2)}`,
                }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <WorkIcon sx={{ mr: 1, color: 'secondary.main' }} />
                <Typography variant="h5" component="h2" sx={{ color: 'secondary.main' }}>
                  Work Experience
                </Typography>
              </Box>
              {workExperience.map((work, index) => (
                <Box 
                  key={index} 
                  sx={{ 
                    mb: index !== workExperience.length - 1 ? 2 : 0,
                    pl: 2,
                    borderLeft: '2px solid',
                    borderColor: 'secondary.main',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      pl: 3,
                      borderColor: 'primary.main'
                    }
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {work.company}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    ({work.companyEn})
                  </Typography>
                  <Typography color="text.secondary" gutterBottom>
                    {work.period}
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {work.position} - {work.type}
                  </Typography>
                  <Typography color="text.secondary">
                    {work.description}
                  </Typography>
                  {work.currentProject && (
                    <Box 
                      sx={{ 
                        mt: 1, 
                        p: 2,
                        borderRadius: 1,
                        backgroundColor: alpha(theme.palette.primary.main, 0.05),
                        border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.1),
                        }
                      }}
                    >
                      <Typography variant="subtitle2" color="primary" gutterBottom>
                        Current Project:
                      </Typography>
                      <Typography variant="body2" fontWeight="medium">
                        {work.currentProject.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {work.currentProject.description}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper 
              elevation={3}
              sx={{ 
                p: 3,
                background: alpha(theme.palette.background.paper, 0.95),
                borderRadius: 2,
                boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.1)}`,
                transform: 'translateY(0)',
                transition: 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
                '&:hover': {
                  transform: 'translateY(-8px) scale(1.02)',
                  background: theme.palette.background.paper,
                  boxShadow: `0 16px 48px ${alpha(theme.palette.common.black, 0.2)}`,
                }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TimelineIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="h5" component="h2" sx={{ color: 'primary.main' }}>
                  Technical Skills
                </Typography>
              </Box>
              <Box 
                sx={{ 
                  mb: 3,
                  p: 2,
                  borderRadius: 1,
                  backgroundColor: alpha(theme.palette.background.paper, 0.7),
                  display: 'flex', 
                  gap: 2, 
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }}
              >
                {(['Expert', 'Advanced', 'Intermediate', 'Basic'] as Skill['level'][]).map((level) => {
                  const colors = getSkillColor(level);
                  return (
                    <Box 
                      key={level} 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1,
                        p: 0.5,
                        borderRadius: 1,
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          backgroundColor: alpha(colors.bg, 0.1),
                        }
                      }}
                    >
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          background: colors.gradient
                        }}
                      />
                      <Typography variant="caption" sx={{ color: colors.border }}>
                        {level}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              {skillCategories.map((category, index) => (
                <Box 
                  key={index} 
                  sx={{ 
                    mb: index !== skillCategories.length - 1 ? 3 : 0,
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: alpha(theme.palette.background.paper, 0.7),
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.background.paper, 0.9),
                    }
                  }}
                >
                  <Typography 
                    variant="subtitle1" 
                    fontWeight="bold" 
                    sx={{ 
                      mb: 1.5, 
                      color: 'primary.main',
                      display: 'flex',
                      alignItems: 'center',
                      '&::before': {
                        content: '""',
                        display: 'block',
                        width: 4,
                        height: 4,
                        borderRadius: '50%',
                        backgroundColor: 'primary.main',
                        mr: 1
                      }
                    }}
                  >
                    {category.category}
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {category.skills.map((skill) => {
                      const colors = getSkillColor(skill.level);
                      return (
                        <Chip
                          key={skill.name}
                          label={skill.name}
                          variant="outlined"
                          size="small"
                          sx={{ 
                            borderColor: colors.border,
                            color: colors.border,
                            backgroundColor: 'transparent',
                            backdropFilter: 'none',
                            transition: 'all 0.3s ease',
                            fontWeight: 500,
                            '&:hover': {
                              backgroundColor: colors.bg,
                              color: 'white',
                              transform: 'translateY(-2px)',
                              boxShadow: `0 4px 8px ${alpha(colors.bg, 0.3)}`,
                              backdropFilter: 'none',
                            },
                            '& .MuiChip-label': {
                              fontSize: '0.875rem',
                            }
                          }}
                        />
                      );
                    })}
                  </Box>
                </Box>
              ))}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default About; 